import {default as React, useState, createRef} from "react"
import qs from "qs"
import useFetch from "use-http"
import Layout from "~/components/layout"
import Seo from "~/components/seo"
import SearchCard from "~/components/search/card"

const SearchPage = ({ location: { search }}) => {
  const ref = createRef()
  const { q } = qs.parse(search.slice(1))
  const [query, setQuery] = useState(q)
  const querystring = qs.stringify({ q: query })
  const { data, loading } = useFetch(`/api/search?${querystring}`, { cachePolicy: 'no-cache' }, [query])

  return (
    <Layout>
      <Seo title="Kirkwood | Search" />
      <section className="pt-40 pb-8 bg-primary">
        <div className="container mx-auto px-8">
          <div className="flex -mx-8">
            <div className="w-full px-8">
              <h1
                className="text-7xl text-white mb-2">Search</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="py-24">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8">
            <div className="w-full px-8">
              <div
                className="flex items-stretch">
                <input
                  ref={ref}
                  defaultValue={query}
                  className={`block w-full rounded-l-md py-2.5 px-4 border-gray-200 bg-gray-50 border-r-0 placeholder-gray-300`}
                  type="text" /> 
                <button
                  disabled={loading}
                  onClick={e => {
                    e.preventDefault()
                    setQuery(ref.current.value)
                  }}
                  className="btn btn-secondary rounded-tl-none rounded-bl-none">Search</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {data?.items && (
        <section className="pb-24">
          <div className="container mx-auto px-8">
            <div className="flex flex-wrap -mx-8">
              <div className="w-full px-8">
                {data?.items.map((item, i) => (
                  <SearchCard
                    key={item.cacheId}
                    {...item} />
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
    </Layout>
  )
}

export default SearchPage