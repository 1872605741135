import {default as React} from "react"

const SearchCard = ({ title = '', link = '', htmlFormattedUrl = '', htmlSnippet = '' }) => {
  return (
    <div
      className="py-4 mb-4">
      <a
        className="flex flex-col" 
        href={link}>
        <h3
          className="text-3xl text-primary">
          { title }
        </h3>
        <div
          dangerouslySetInnerHTML={{
            __html: htmlFormattedUrl
          }}
          className="text-secondary my-2" />
        <div
          dangerouslySetInnerHTML={{
            __html: htmlSnippet
          }}
          className="text-gray-500" />
      </a>
    </div>
  )
}

export default SearchCard